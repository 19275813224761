/* CSS is in the homeheader.css file */

import { Component } from 'react';

import "./backpageheader.css"

export default class BackPageHeader extends Component {
    render() {
        return(
            <div id="bp-header-container" className="container-fluid ">
                
                <div className="jumbotron" id="bp-jumbotron">
                    <h1 className="backpage"><span className="bp-BristolHead">Bristol</span><span className="bp-SingsHead">Sings</span></h1>
                </div>
            
            </div>
        )
    } 
}