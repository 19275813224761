import { Component } from 'react';

import  axios from 'axios';

import './newsStory.css';


export default class NewsStory extends Component {
    constructor() {
        super();
        this.state = {
            newsData: [],
            newsListData: [], 
            
        }
    }

    componentDidMount() {
        
        var baseurl = "https://www.bristolsings.org/api/wp-json/wp/v2/posts/?include[]=" + this.props.id;
            axios.get(baseurl)
            .then(res => {

                this.setState({ newsData : res.data })
                
            })
        var newsBaseUrl = "https://www.bristolsings.org/api/wp-json/wp/v2/posts/?categories=3";
            axios.get(newsBaseUrl)
            .then(res2 => {
                this.setState({ newsListData: res2.data})
                
            })
    }
    render() {
        
        return (
            <div className="container newsStory" >
                {this.state.newsData.map(single=> {
                    return(
                        <div key="story">
                            <div className="row">
                                <div className="col-12">
                                    <h2 className="headline">
                                        {single.title.rendered}
                                    </h2>
                                </div>
                            </div>
                        </div>

                    );
                })}

                <div className="row">
                <div className="col-sm-8">
                  {this.state.newsData.map(single=> {
                        return (
                        <div  key="story">
                            
                            <div dangerouslySetInnerHTML={createMarkup(single.content.rendered)} />
                        </div>
                        );
                    })}
                </div>
                <div className="col-sm-4 news-story-rightcol">
                    <h3>Other stories</h3>
                    <ul>
                            {this.state.newsListData.map(newsList=> {
                                return (
                                    <li key="listit"><a href={"/news/" + newsList.id } >{newsList.title.rendered}</a></li>
                                )
                            })}
                    </ul>
                   
                    </div> 
                </div>
            </div>
        )
    }
}

function createMarkup(htmlIn) {
    return {__html: htmlIn};
}