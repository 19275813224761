import React, { Component } from 'react';
import axios from 'axios';

import './homeNews.css';

export default class HomeNews extends Component {

    constructor(props) {
        super(props) 
            this.state = { newsData: []}
    }

    componentDidMount() {
        var baseurl = "https://bristolsings.org/api/wp-json/wp/v2/posts/?categories=3&_embed";
        axios.get(baseurl)
            .then(res => {
                
                this.setState({ newsData: res.data })
                
            })
    }

    doItem() {
        
        return this.state.newsData.map((data, i) => {
            return ( 
               
                <div className="col-sm-4 text-center" obj={data} key={i}>
                    <div className="card lep-card">
                        
                        <img className="card-img-top lep-card-image" src={data._embedded['wp:featuredmedia'][0].media_details.sizes.medium.source_url} alt="News" />
                        <div className="card-body">
                       
                            <h5 className="card-title">{data.title.rendered}</h5>
                            <p><span className="card-text" dangerouslySetInnerHTML={{__html:data.excerpt.rendered}}></span>
                               

                            <a href={"news/" + data.id}>Read more &gt;&gt; </a></p>
                            
                            
                        </div>
                    </div>
                </div>
            )
        })
        
    }


    render() {
        return (
            
            <div className="container">
                <div className="row">
                    {this.doItem()}
                </div>
            </div>
        )
    }
}