import { Component } from 'react';
import './navbar.css';

export default class NavBar extends Component {
    render() {
        return (
            <nav className = "navbar navbar-expand-sm bg-dark">
                <a className = "navbar-brand" href="/">Bristol Sings</a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" 
                    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item">
                            <a className="nav-link" href="/news">News</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/teachers">Singing Teachers</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/events">Events</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/choirs">Choirs</a>
                        </li>
                    </ul>
                </div>
            </nav>
        ) 
    }
}