import { Component } from 'react';
import BackPageHeader from '../../backpageheader.js';
import './newsStory.css';

export default class NewsPage extends Component {
    render() {
        return (
            <div>
                <BackPageHeader></BackPageHeader>
                <div>News sheet stuff</div>
            </div>
        )
    }
}