import { Component } from 'react';
import axios from 'axios';

import BackPageHeader from '../../backpageheader.js';
import { Card } from 'react-bootstrap';

export default class TeacherList extends Component {

    constructor(props) {
        super(props) 
            this.state = { teacherData: []}
    }

    componentDidMount() {
        var baseurl = "https://www.bristolsings.org/api/data/teacher/";
            axios.get(baseurl)
            .then(res => {

                this.setState({ teacherData : res.data })
                console.log(res.data)
            })
    }

    doTeachersList() {
        
        return this.state.teacherData.map((data, i) => {
            return ( <div obj={data} key={i} className="col-sm-4">
                <Card>
                <Card.Img variant = "top" src={"https://bristolsings.org/teachers/" + data.image} />
                <Card.Body> 
                    <Card.Title>{data.name}</Card.Title>
                    
                    <Card.Text>{data.text}</Card.Text>
                    <Card.Link href={"mailto:" + data.email}>Email</Card.Link>
                </Card.Body>
                </Card>
            </div>)
        })
    }

    render() {
        return (
            <div>
                <BackPageHeader></BackPageHeader>
                <div className="container">
                <h2>Teachers</h2>
                <div>Our listing of singing teachers: </div>
                    <div >{this.doTeachersList()}</div>
                </div>
                
            </div>
        )
    }
}