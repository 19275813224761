import { Component } from 'react';
import axios from 'axios';

import './homePanels.css';

export default class HomePanels extends Component {

    constructor(props) {
        super(props) 
            this.state = { panelData: []}
    }

    componentDidMount() {
        var baseurl = "https://bristolsings.org/api/data/get_icon.php";
        axios.get(baseurl) 
            .then(res => {
                this.setState({ panelData: res.data })
                
            })
    }

    doItem() {
        const regex = /(<([^>]+)>)/ig;
        return this.state.panelData.map((data, i) => {
            console.log("WHere")
            return ( 
                    <div className="col-sm-4 text-center" obj={data} key={i}>
                        <div className="card lep-card icon-card">
                        
                            <i className={"fa " + data.icon + " lep-icon"}></i> 
                            <div className="card-body">
                                <h5 className="card-title lep-panel-title">{data.title}</h5>
                                <p className="card-text">{data.content.replace(regex, '')}</p>
                            </div>
                        </div>
                    </div>

            )
        })
        
    }


    render() {
        return (
            
            <div className="container-fluid">
                <div className="row homePanels_wrap">
                    {this.doItem()}
                </div>
            </div>
        )
    }
}