import React, { Component } from 'react';
import axios from 'axios';

import "./homeExplain.css"

export default class HomeExplain extends Component {

    constructor(props) {
        super(props) 
            this.state = { text: []}
    }

    componentDidMount() {
        // a fixed post - 12 is the standfirst
        var baseurl = "https://bristolsings.org/api/wp-json/wp/v2/posts/12";
        axios.get(baseurl)
            .then(res => {
                this.setState({ text: res.data.content })
            })
            .catch(error => {
                // console.log(error)
            })
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 standfirst text-center"
                        dangerouslySetInnerHTML={{ __html: this.state.text.rendered}}>
                    </div>
                </div>
            </div>
        )
    }
}