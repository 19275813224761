import { Component } from 'react';
import axios from 'axios';
import './choir.css';
import BackPageHeader  from '../../backpageheader';
import {
    Container, Row, Col
  } from 'reactstrap';


export default class ChoirList extends Component {

    constructor(props) {
        super(props)
            this.state = { choirData: []}
    }

    componentDidMount() {
        var baseurl = "https://www.bristolsings.org/api/data/choir/";
            axios.get(baseurl)
            .then(res => {

                this.setState({ choirData : res.data })
                console.log(res.data)
            })
    
    }

    doChoirList() {

            return ( 
                
                <Container>
                   <Row sm={1} className="">
                   {this.state.choirData.map((data, i) => {
                        function createMarkup() {
                            return {__html: data.extras};
                          }
                        function getLogo() {
                            if (data.logo > "/choirs/") {
                                var thisLogo = <img src={data.logo} className="choirLogoImage" alt="Choir logo" />;
                            } else {
                                thisLogo = ""
                            }
                            return thisLogo;
                        }  
                        return (
                            <Col key={data.id}>
                            <li className="choirCard">
                                <div className="choirHeader"><h2 >{data.name}{getLogo()}</h2></div>
                                <div>{data.text}</div>
                                
                                <div><a href={data.website}>{data.website}</a></div>
                                <div dangerouslySetInnerHTML={createMarkup()}></div>
                            </li>
                            </Col>
                        )
                    })}</Row>
                </Container>
               

        ) 
    }

    render() {
        return (
            <div>
                <BackPageHeader></BackPageHeader>
                <div className="container">
                <h2>Choirs</h2>
                <div>Our listing of choirs: </div>
                    <div className="container" >{this.doChoirList()}</div>
                </div>
                
            </div>
        )
    }
}