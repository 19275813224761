import { Component } from 'react';
import HomeHead from './homeheader.js';
import HomePanels from './homePanels.js';
import HomeExplain from './homeExplain.js';
import HomeNews from './homeNews.js';


export default class HomePage extends Component {
    render() {
        return (<div>
            <HomeHead></HomeHead>            
            <HomeExplain></HomeExplain>
            <HomePanels></HomePanels>
            <HomeNews></HomeNews>
            </div>
        )
    }
}
