// import logo from './logo.svg';
import './App.css';
import React from 'react';
import { BrowserRouter, Route, Switch, useParams } from 'react-router-dom';
import NavBar from './navbar.js';
import HomePage from './pages/home/home.js'
import NewsStory from './pages/news/newsStory.js';
import NewsPage from './pages/news/newsPage.js';
import TeacherList from './pages/teachers/teacherlist.js';
import EventList from './pages/events/eventlist.js';
import ChoirList from './pages/choirs/choirlist.js';
import About from './pages/home/about.js';
import Footer from './footer.js';

function App() {
  return (
    <div>
      <NavBar></NavBar>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact>
            <HomePage></HomePage>
          </Route>
          <Route path="/news" exact>
            
            <NewsPage></NewsPage>
          </Route>
          <Route path="/news/:storyId" component={SendParam} />
          <Route path="/teachers" exact>
            <TeacherList></TeacherList>
          </Route>
          <Route path="/events" exact>
            <EventList></EventList>
          </Route>
          <Route path="/about" exact>
            <About></About>
          </Route>
          <Route path="/choirs" exact>
            <ChoirList></ChoirList>
          </Route>
        </Switch>
      </BrowserRouter>
      <Footer></Footer>
     
    </div>
  );
}

export default App;

function SendParam() {
  const {storyId} = useParams();
  console.log("Id: " + storyId);

  return <div>
    <NewsStory id={storyId} />
  </div>
}
