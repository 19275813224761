import { Component } from 'react';
import "./homeheader.css";

export default class HomeHead extends Component {
    render() {
        return (
            <div className="container-fluid" id="homeJumboContainer" >
            <div id="homeJumbotron" className="jumbotron homeJumbotron">
                <h1 className="homepage"><div className="BristolHead">Bristol</div><div className="SingsHead">Sings</div></h1>
            </div>
            </div>
        )
    }
} 