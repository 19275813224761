import BackPageHeader from "../../backpageheader";
import { Component } from 'react';

export default class About extends Component {
    render() {
        return (
            <div>
                <BackPageHeader></BackPageHeader>
                <div class="container">
                <h2>About Bristol Sings!</h2>
                
                <p>
                    Bristol Sings! is run by Allegro Brio, and aims to bring news about singing 
                    in Bristol, and about singers in Bristol, to everyone's attention. 
                </p>
                <p>
                    Allegro Brio address:
                    <p>8 Filton Road,<br />
                    Bristol <br />
                    BS16 1QL</p> 
                </p>
                <p>Allegro Brio is run by Martin Le Poidevin. Martin is a singing teacher, performer
                    and the curator of the Bristol Song Recital Series. He is based in Bristol, 
                    and is a former journalist. After a lifetime of working in and around Bristol he 
                    is perfectly placed to pick up all the information about what's happening on the Bristol 
                    singing scene. 
                </p>
                <p>   </p>
                </div>
            </div>
        )
    }
}
