import { Component } from 'react';
import axios from 'axios';

import { Html5Entities } from 'html-entities';


import BackPageHeader  from '../../backpageheader';
import './events.css';
import {
    Container, Row, Col, Button
  } from 'reactstrap';

export default class EventList extends Component {

    constructor(props) {
        super(props)
            this.state = { eventData: []}
    }

    componentDidMount() {
        var baseurl = "https://www.bristolsings.org/api/data/event/";
            axios.get(baseurl)
            .then(res => {

                this.setState({ eventData : res.data })
                
            })
    
    }
 
    doEventsList() {

        // return this.state.eventData.map((data, i) => {
             
            // the first side    
            
            return ( 
                
                <Container>
                  
                  {this.state.eventData.map((data, i) => {
                        var keyValue = "data" + i.toString();
                        return (
                        
                        <Row key={keyValue}>
                            <Col className="eventLePCol">
                                <div className="eventLePCard">
                                    <div className="eventRow" >
                                        <div className="eventDateBlockWrap">
                                            <div className="eventDateBlock">
                                                <div className="eventDateMonth">{data.month}</div> 
                                                <div className="eventDateDay">{data.day}</div>
                                            </div>
                                        </div>
                                        <Row className="eventInfo">
                                            <Col sm="10">
                                                <div className="eventTitleDiv"><h3 className="eventTitle">{data.title}</h3></div>
                                                <div className="eventInfoLine">{data.location} | {data.time} | {data.prices} </div>
                                                <div className="eventTextLine">{data.text}</div>
                                                <div className="moreButtonLine">
                                                    <Button className="eventMoreButton" title="Right button"
                                                        onClick={() => console.log('Right button pressed')} >More info
                                                    </Button> 
                                                </div>
                                            </Col>
                                            <Col sm="2" className="eventPoster">
                                                <img src={data.image} />
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                      )})}
                </Container>
               
        ) 
    }

    infoRow(data) {
        const htmlEntities = new Html5Entities();
        return(
            
            <div className="eventInfoRow">
                <div className="eventDivider"></div>
                <div><a href={data.websitelink}>Website</a> | {htmlEntities.decode(data.prices)}</div>
            </div>
        )
    }

    picShow(image) {
        if (image > "")  {
        
            return <div className="imageFltRt"><img src={image} alt="Tessitoura event image" /></div>
        } else {
            return <div>There is no image</div>
        }
    }

    eventShow(data) {
        return(
            <div className="eventLePCard">
                <h2 className="eventTitle">{data.title}</h2>
                <div className="eventsubTitle">{data.date} &#8226; {data.location}  &#8226; {data.time}</div>
                <Container>
                    <Row className="eventMainRow">
                        <Col className="eventmaintextLeft">{data.text_excerpt}</Col>
                        <Col className="eventmaintextRight"><img className="eventPoster" src={data.image}></img></Col>
                    </Row>
                </Container>
               
                <Container>
                    <Row>
                        
                        {this.infoRow(data)}
                    </Row>
                </Container>
            </div>
        )
    }


    render() {
        return (

            <div>
                <BackPageHeader></BackPageHeader>
                
                <div className="container">
                <h2>Events</h2>
                    
                    <div className="container" >{this.doEventsList()}</div>
                </div>
                
            </div>
        )
    }
}