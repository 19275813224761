import { Component } from 'react';

import "./footer.css"

export default class Footer extends Component {
    render() {
        return (
            <footer>
                <div className="footer-wrap container">
                    <div className="row">
                        <div className="col-sm-6">
                            <p>Bristol Sings! is created by Allegro-Brio</p> 
                        </div>
                        <div className="col-sm-6 footer-right-col">
                            <p>More details</p> 
                            <p>Contact us</p>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}